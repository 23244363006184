@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

.toastBody {
  font-family: 'Roboto', sans-serif;
  color: #000;
}

.toastProgress {
  background: #000 !important;
}

.toastBodyDark {
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.toastProgressDark {
  background: #fff !important;
}

.textarea-container {
  position: relative;
}

.textarea {
  resize: none;
  overflow-y: hidden;
}

.textarea::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.hyperlink{
  cursor: pointer;
}

.hyperlink:hover, .hyperlink-active{
  color: #c77f14!important; 
}


.btn{
  background: #c77f14;
  color: #FFFFFF;
  border-color: #f5ba36;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover{
  background: #8b580d!important;
}

.btn-2{
  background: #bbb622;
  color: #FFFFFF;
  border-color: #f5ba36;
  border-radius: 5px;
  cursor: pointer;
}

.btn-2:hover{
  background: #9d9818!important;
}

.btn-3{
  background: #ed2a4f;
  color: #FFFFFF;
  border-color: #f5ba36;
  border-radius: 5px;
  cursor: pointer;
}

.btn-3:hover{
  background: #cf1d3f!important;
}

.text-pink{
  color: #ed2a4f;
}

.cursor-pointer{
  cursor: pointer;
}